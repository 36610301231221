.linebreak {
  width: 80%;
  padding-left: 10%;
  height: auto;
  margin: 0;
}

@media (max-width: 767px) {
  .linebreak{
    padding: 40px 10%;
    /* margin: ; */
  }
}

.linebreak img {
  width: 100%;
  height: 2px;
}
