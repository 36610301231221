#projectlisting {
  margin: 0;
  padding: 0;
  position: relative;
}

.projectheroimgs {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  object-fit: contain;
  background-color: black;
}

#projecthero {
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

#projecttypes {
  margin: auto 10%;
}

.projecttypestitle {
  font-size: 0.9rem;
  font-weight: regular;
  margin: 1rem 0;
}

.projecttypes {
  display: flex;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
}
#heronav{
  color:white;

}
.projectypebutton {
  padding: 0.6rem 1.3rem;
  font-weight: 400;
  color: #707070;
  border: #707070 solid 2px;
  font-size: 0.7rem;
  letter-spacing: 2px;
  border-radius: 28px;
  text-transform: uppercase;
  transition: background-color 0.3s;
  cursor: pointer;
}
.projectypebutton.selected {
  background-color: #707070;
  color: white;
  cursor: default;
}

.projectypebutton:hover {
  background-color: #dedede;
}

.projectypebutton.selected:hover {
  background-color: #707070;
  color: white;
}

#projectcards {
  margin: 4rem 10%;
  cursor: pointer;
}

.projectcards {
  display: grid;
  grid-template-columns: repeat(4, minmax(300px, 1fr));
  gap: 0.9rem;
}
.cardimg img {
  transition: 500ms all ease;
}
.cardimg:hover img {
  transform: scale(1.05);
}

.projectcard .cardtoptext {
  font-size: 0.7rem;
  font-weight: regular;
  margin: 0.5rem 0;
  text-transform: uppercase;
}
.projectcardbottom {
  margin: 1rem 0;
  margin-bottom: 2.3rem;
}

.projectcardtitle {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  letter-spacing: 0.12rem;
}
.projectcardcaption {
  font-size: 0.9rem;
  font-weight: regular;
  margin: 0.5rem 0;
}
.cardimg {
  overflow: hidden;
  aspect-ratio: 1.8 / 1;
}
.cardimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.viewmorebtnp {
  display: flex;
  margin: 4rem 10%;
  background: transparent;
}
.viewmorebtnp > .viewmorebtn {
  margin: 1rem 1.4rem;
  min-width: max-content;
  padding: 20px;
  font-size: 1rem;
  font-weight: bold;
  border: #000 solid 3px;
  letter-spacing: 2px;
  background-color: white;
  /* background-size: 200% 100%;
  background-position: right bottom; */
  transition: all 0.5s ease-out;
}
.viewmorebtn:hover {
  background-color: black !important;
  color: #fff;
}
.viewmorebtnline {
  width: 100%;
  background-color: #000;
  box-sizing: border-box;
  height: 1px;
}

@media only screen and (min-width: 1000px) {
  .projectcards .projectcards {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .projectcards .projectcard:nth-child(4) {
    grid-row: 2 / 4;
  }
  .projectcards .projectcard:nth-child(4) .cardimg {
    aspect-ratio: unset;
  }
  .projectcards .projectcard:nth-child(14) {
    grid-row: 5 / 7;
    grid-column: 2 / 4;
  }
  .projectcards .projectcard:nth-child(14) .cardimg {
    aspect-ratio: unset;
  }
}

@media only screen and (min-width: 732px) {
  .projectcards .projectcards {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
}

@media only screen and (max-width: 767px) {
  #heronavprojecthome {
    display: none;
  }
  #fixit {
    display: none;
  }
  .carouselbutton {
    right: 2rem !important;
  }
  .projectypebutton {
    font-size: 8px;
  }
  .projectcards {
    display: grid;
    grid-template-columns: repeat(1, minmax(300px, 1fr));
    gap: 0.9rem;
  }
  .projectcard .cardtoptext {
    font-size: 12px;
  }
  .projectcardtitle {
    font-size: 16px;
    padding: 10px 0px;
  }
  .projectcardcaption {
    font-size: 12px;
  }
  .project_home {
    overflow: hidden;
  }
}
