 * {
  text-decoration: none;
  font-size: 1.11vw;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  background-color: #ffffff;
  align-items: center;
  padding: 0;
  color: #000;
  margin: 0;
}

body {
  text-decoration: none;
  font-size: 1.11vw;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  background-color: #ffffff;
  align-items: center;
  padding: 0;
  color: #000;
  margin: 0;
  overflow-x: hidden;
}
.projectheroimg{
  cursor: pointer;
}



