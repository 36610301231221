#newsletter {
  padding-left: 10%;
  width: 90%;
  height: 36rem;
  margin: 0;
  padding-top: 0;
  padding-bottom: 3rem;
}

#newsimg {
  margin: 100px 0;
  margin-bottom: 0;
  height: auto;
  width: 35%;
  float: left;
}

#newstext {
  width: 48%;
  margin-top: 10rem;
  float: left;
  margin-left: 15%;
}

#newstext h3 {
  height: 2.5rem;
  margin-bottom: 1.5rem;
  font-size: 2rem;
  letter-spacing: 3px;
}

#newstext p {
  font-weight: 300;
  margin-top: 1rem;
  line-height: 13 0%;
  letter-spacing: 1px;
  width: 35.375rem;
  height: auto;
}

#emailbox {
  float: left;
  margin-left: 15%;
}
#emailbox p {
  padding: 0;
  margin: 0;
  margin-top: 24px;
  margin-bottom: 16px;
}

#email {
  width: 316px;
  height: 55px;
  padding-left: 38px;
  border: none;
  background-color: #f5f5f5;
}
#subscribe {
  /* padding: 17px 61px 19px 50px; */
  color: white;
  border: none;
  font-size: 0.8rem;
  letter-spacing: 2px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  background-color: #595959;
  box-shadow: none;
  height: 55px;
  width: 212px;
  transition: all 0.3s ease;
  text-transform: uppercase;
}
#subscribe:hover {
  background-color: #404040;
  cursor: pointer;
}
@media only screen and (max-width: 767px) {
  #newsletter {
    height: 100rem;
  }
  #newsimg {
    width: 90%;
  }
  #newstext {
    width: 90%;
    margin-top: 10rem;
    float: left;
    margin-left: 0;
  }
  #newstext h3 {
    font-size: 25px;
    padding-bottom: 3vh;
    width: 100%;
  }
  #newstext p {
    font-size: 16px;
    width: 100%;
  }
  #emailbox {
    margin-left: 0;
    height: 25rem;
  }
  #emailbox p {
    padding: 0;
    margin: 0;
    margin-top: 24px;
    margin-bottom: 16px;
  }
  #email {
    width: 100%;
    height: 55px;
    padding-left: 12px;
  }
  #email::placeholder {
    font-size: 16px;
  }
  #subscribe {
    /* padding: 17px 61px 19px 50px; */
    font-size: 14px;
    height: 48px;
    width: 150px;
    margin-top: 1.5vh;
    margin-bottom: 20vh;
    transition: all 0.3s ease;
    text-transform: uppercase;
  }
}
