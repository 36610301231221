.vacancy_card * {
  color: black;
  background-color: transparent;
}
.vacancy_card {
  background-color: black;
  padding-left: 10px;
  width: 80.2%;
  margin: 0 auto;
  height: 100px;
  overflow: hidden;
  transition: all ease 1s;
  margin-top: 1.5rem;
}
.vacancy_card_content {
  background-color: #f5f5f5;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 6rem;
  transition: all ease 1s;
}
.vacancy_card_content_wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all ease 1s;
}
.vacancy_card_content .contenthead {
  font-weight: bold;
  font-size: 1.2rem;
  /* letter-spacing: 5px; */
  margin-top: 2rem;
}
.vacancy_card_content .contentsubhead {
  font-weight: 400;
  font-size: 0.8rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-top: 0.4rem;
}
.vacancy_card_content .contenttext {
  /* display: none; */
  overflow: hidden;
  height: 0;
  font-weight: 300;
  font-size: 1rem;
  margin-top: 2rem;
  padding-right: 17rem;
  box-sizing: border-box;
  transition: all ease 1s;
}
.vacancy_card_button_wrap {
  width: 50%;
  padding-right: 5rem;
}

.vacancy_card .viewmorebtn {
  margin: 1rem 1.4rem;
  float: right;
  min-width: max-content;
  padding: 20px;
  font-size: 1rem;
  font-weight: bold;
  border: #000 solid 3px;
  letter-spacing: 2px;
  background: white 50%;
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.2s ease-out;
  cursor: pointer;
}
.vacancy_card_button_wrap .viewmorebtn {
  padding: 0.7rem 2rem;
  font-weight: normal;
  text-transform: uppercase;
  border-width: 2px;
  opacity: 0;
}
.vacancy_card:hover {
  height: fit-content;
  background-color: #e82127;
}
.vacancy_card:hover .viewmorebtn {
  opacity: 1;
}
.vacancy_card:hover .contenttext {
  height: 100px;
}
.vacancy_card:hover .vacancy_card_content {
  height: 120px;
}
.vacancy_card:hover .vacancy_card_content_wrap {
  margin-top: 5rem;
}

@media (min-width:1900px) {
  .vacancy_card {
    height: 250px !important;
  }
  .vacancy_card_content{
    height: 250px !important;
  }
}